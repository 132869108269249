.personalInfoButton {
  padding: 16px;
  border: 1px solid var(--primary-05);
  border-radius: 24px;

  &:hover {
    background-color: var(--primary-02);
    cursor: pointer;
  }
}

.personalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  font-weight: bold;
}

.popperWrapper {
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid black;
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.personalInfoInPopper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.domainsWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .domainsHeader {
    font-weight: bold;
  }
}

.domain {
  display: flex;
  align-items: center;
  gap: 4px;
}

// status badges

.badge {
  border-radius: 40px;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
  }

  &.pending {
    background-color: #8c8c8c26;
    color: var(--primary-09);
    border: 1.5px solid var(--primary-09);
  }

  &.failure {
    background-color: #ff733326;
    color: #993000;
    border: 1.5px solid #993000;
  }

  &.success {
    background-color: #00990026;
    color: #006600;
    border: 1.5px solid #006600;
  }
}
