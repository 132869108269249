.domainRow {
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

  &:focus {
    background-color: #f5f5f5;
  }
}

.sortLable {
  &:focus {
    text-decoration: underline;
  }
}
