.navWrapper {
  //   background-color: white;
  // position: relative;
}

.nav {
  background-color: #212121;
}

.header {
  color: #fff;
  font-weight: bold;
  font-size: 30px;
}

.link {
  text-decoration: none;
  padding: 10px;
  transition: 0.5s;
  &:hover {
    background-color: rgb(77, 77, 77, 0.5);
  }
}

.active {
  border: 1px solid #fff;

  .link {
    &:hover {
      cursor: auto;
      background-color: #212121;
    }
  }
}

.text {
  color: #fff;
}

.icon {
  color: #fff;
}
