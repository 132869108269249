.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-4);
}

// wrapper of TabListItems
.wrapper {
  display: flex;
  gap: var(--spacing-1);
  border: 1px solid var(--primary-03);
  padding: 4px;
  border-radius: 4px;
  min-width: 150px;
  background-color: transparent;
}

.tabListItem {
  font-family: var(--regular-header);
  flex-grow: 1;
  font-size: 16px;
  background-color: var(--primary-01);
  border: 1px solid var(--primary-01);
  border-radius: 2px;
  color: var(--primary-07);
  padding: 4px var(--spacing-2);

  &.MuiAccordionSummary-content {
    margin: 24px 0;
  }

  &.active {
    background-color: var(--primary-07);
    color: #fff;
    border: 1px solid var(--primary-07);

    &.positive {
      background-color: #006600;
      border: 1px solid #006600;
      color: #fff;
    }

    &.negative {
      background-color: #e74c3c;
      border: 1px solid #e74c3c;
      color: #fff;
    }
  }

  &:focus {
    outline: none;
    border: 1px solid var(--primary-08);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--primary-02);
    border: 1px solid var(--primary-07);
  }
}
