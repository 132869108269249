.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  height: 100%;
  min-height: 70vh;
  width: 100%;
}

.scrollToTopButton {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100;
  padding: 16px;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  padding: 16px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  border: 2px solid black;

  &:hover {
    cursor: pointer;
  }
}

.loadingState {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  gap: 24px;
  width: 50vw;
  height: 50vh;
  justify-content: center;
  width: 100%;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  border: 2px solid black;
  padding: 24px;
  width: 40vw;
  margin: 0 auto;
}

@media screen and (max-width: 450px) {
  .content {
    padding: 16px;
    margin: 0 16px;
  }
}
