.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcd7c2;
  width: 50vw;
  height: 40vw;
  border-radius: 20px;
}
