.tabContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tabHeader {
  text-align: center;
}

.imagesHolder {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  max-width: 90vw;
  width: 100%;
  gap: 16px;
  justify-content: center;
  margin: 0 auto;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
}
