.brokenCardWrapper {
  background-color: black;
  color: #fff;
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
}

.brokenCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 4px;
}

.header {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
}

.logoWrapper {
  svg {
    color: #fff;
    width: 72px;
    height: auto;
  }
}
