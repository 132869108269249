.wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 80vw;
  }
  
  .tableWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .tableControlsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  
  .searchField {
    width: 350px;
  }
  
  .searchButton,
  .clearButton {
    background-color: transparent;
    padding: 4px;
    border-radius: 4px;
    outline: none;
    border: 1px solid black;
  
    &:hover {
      cursor: pointer;
      background-color: #2d2d2d;
      color: #eaeaea;
    }
  }
  
  .clearButton {
    margin-right: 8px;
  }
  
  .itemsPerPageSelect {
    display: flex;
    flex-direction: column;
    align-self: end;
    margin-bottom: 20px;
  }
  
  // action buttons
  
  .actionButtonsWrapper {
    display: flex;
    gap: 10px;
    background-color: white;
    border: 2px solid #212121;
    position: fixed;
    bottom: 0;
    display: flex;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 20px;
  
    .reviewButton,
    .verifyButton,
    .processButton {
      padding: 8px;
      border-radius: 8px;
      border: 1px solid black;
      font-size: 10px;
      font-weight: bold;
      color: #212121;
  
      &:hover {
        cursor: pointer;
        background-color: #2d2d2d;
        color: #eaeaea;
      }
    }
  
    .dividerLine {
      height: auto;
      width: 2px;
      margin: -10px 0;
      background-color: black;
    }
  }
  
  .paginationHolder {
    margin-top: 16px;
    display: flex;
    align-self: flex-end;
  }
  