.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 80vw;
}

.userInfoWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .userButtonsWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .verifyUserButton,
  .trustUserButton {
    padding: 8px;
    border-radius: 40px;
    border: 1px solid black;

    &:hover {
      cursor: pointer;
      background-color: #2d2d2d;
      color: #eaeaea;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;

  .buttons {
    display: flex;
    gap: 20px;
    position: fixed;
    bottom: 24px;
    z-index: 9;
    background-color: white;
    border: 2px solid #212121;
    padding: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
  }
}

.imagesHolder {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

.imageWrapper {
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  padding: 10px;
}

.previewModal {
  z-index: 2000;
  img {
    width: inherit;
  }
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .select {
    min-width: 200px;

    div {
      padding: 10px !important;
    }
  }
}

.selectAllCheckBoxWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.selectAllCheckBox {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 1300px) {
  .imagesHolder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

@media screen and (max-width: 680px) {
  .imagesHolder {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}
