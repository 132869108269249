.imageWrapper {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: end;

  &:hover {
    cursor: pointer;
    .hoverBox {
      visibility: visible;
    }
  }
}

.isChecked {
  box-shadow: 0px 0px 1px 4px #7eaeda;
}

.image {
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}

.audioPlayerWrapper,
.videoPlayerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}

.hoverBox {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 78%;
  background-color: rgba(114, 114, 114, 0.9);
  display: flex;
  justify-content: center;

  .innerHoverBox {
    display: flex;
    justify-content: center;
    align-items: center;

    .previewButton {
      background-color: transparent;
      border: 2px solid black;
      border-radius: 5px;
      padding: 10px;
      text-transform: uppercase;
      font-size: 16px;

      &:hover {
        cursor: pointer;
        background-color: #2d2d2d;
        color: #eaeaea;
      }
    }
  }
}

.selectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .selectionInfoWrapper {
    display: flex;
    align-items: end;
    gap: 2px;
    width: 100%;
    justify-content: space-between;
  }
}

.checkBox {
  width: 25px;
  height: 25px;

  &:hover {
    cursor: pointer;
  }
}
