.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 80vw;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
}

.tableControlsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchField {
  width: 350px;
}

.searchButton,
.clearButton {
  background-color: transparent;
  padding: 4px;
  border-radius: 4px;
  outline: none;
  border: 1px solid black;

  &:hover {
    cursor: pointer;
    background-color: #2d2d2d;
    color: #eaeaea;
  }
}

.clearButton {
  margin-right: 8px;
}

.itemsPerPageSelect {
  display: flex;
  flex-direction: column;
  align-self: end;
  margin-bottom: 20px;
}

.paginationHolder {
  margin-top: 16px;
  display: flex;
  align-self: center;
}
