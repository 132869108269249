.infoBarWrapper {
  display: flex;
  background-color: #fff;
  padding: 24px;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
}

.selectAllCheckBoxWrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    cursor: pointer;
  }
}

.selectAllCheckBox {
  width: 30px;
  height: 30px;
}

.message {
  justify-self: center;
  font-size: 18px;
  font-weight: bold;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;
}

.approveButton,
.rejectButton {
  &:disabled {
    background-color: var(--primary-01);
  }
}
