.lozenge {
  padding: 1px 7px;
  width: fit-content;
  border-radius: 14px;
  text-transform: uppercase;
  font-size: 10px;
  .not {
    font-weight: bold;
  }

  &.positive {
    background-color: #b6d7a8;
  }

  &.negative {
    background-color: #ea9999;
  }
}
