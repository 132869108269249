.tabsContainer {
  width: 100%;
}

.tabsList {
  width: 100% !important;
  gap: 8px;
}

.tabItem {
  padding: 8px;
  font-size: 20px;
  font-weight: bold;
  border: 2px solid var(--primary-07);
  color: #000;

  &.approvedTab {
    border: 2px solid #006600;
    background-color: #00660066;

    &:focus {
      color: #fff;
      background-color: #006600;
      border: 2px solid #006600;
    }
  }

  &.rejectedTab {
    border: 2px solid #e74c3c;
    background-color: #e74c3c66;

    &:focus {
      color: #fff;
      background-color: #e74c3c;
      border: 2px solid #e74c3c;
    }
  }

  &:focus {
    color: #fff;
    background-color: var(--primary-07);
    border: 2px solid var(--primary-07);
  }
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  border: 2px solid black;
  padding: 24px;
  width: 40vw;
  margin: 0 auto;
}

.loadingState {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  gap: 24px;
  width: 50vw;
  height: 50vh;
  justify-content: center;
  width: 100%;
}

.previewImageWrapper {
  width: 100%;
  max-height: 600px;
  height: 100%;
  max-height: 600px;
  max-width: 30vw;

  .previewImageUrl {
    overflow-wrap: break-word;
  }

  .previewImage {
    width: 100%;
    height: 100%;
    max-height: 500px;
  }
}

@media screen and (max-width: 450px) {
  .tabsList {
    display: flex;
    flex-direction: column;
  }
}
